<template>
  <v-navigation-drawer
    fixed
    :clipped="$vuetify.breakpoint.lgAndUp"
    app
    :value="sidebarState"
    @input="consisteSidebar"
  >
    <v-list dense>
      <template v-for="(item, index) in items">
        <v-list-group
          v-if="item.children"
          v-model="item.model"
          :key="item.text"
          :prepend-icon="item.model ? item.icon : item['icon-alt']"
          append-icon=""
        >
          <v-list-item slot="activator">
            <v-list-item-title>
              <v-list-item-title :style="`color: ${item.color};`">
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-for="(child, i) in item.children"
            :key="i"
            @click="navigateTo(child.nameRouter)"
          >
            <v-list-item-action v-if="child.icon">
              <v-icon>{{ child.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-title>
              <v-list-item-title>
                {{ child.text }}
              </v-list-item-title>
            </v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-item
          v-else
          @click="navigateTo(item.nameRouter)"
          :class="item.class"
          :key="item.text"
        >
          <v-list-item-action>
            <v-icon :color="item.color">{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-title :class="`${item.color}--text subheading`">
            {{ item.text }}
          </v-list-item-title>

          <v-list-item-action
            v-if="!!item.urlVideo"
            @click="
              dialogsChangeVideoShow({ status: true, url: item.urlVideo })
            "
          >
            <v-icon>ondemand_video</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-divider
          v-if="index + 1 < items.length"
          :key="`divider-${index}`"
        ></v-divider>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  computed: {
    ...mapState({
      sidebarState: (state) => state.layout.sidebar,
    }),
  },
  data: () => ({
    items: [
      {
        icon: 'add_circle_outline',
        color: 'indigo',
        text: 'Ganhe descontos',
        nameRouter: 'vouchers',
        urlVideo: null,
        class: 'py-3',
      },
      {
        icon: 'fa-university',
        color: 'orange',
        nameRouter: 'taxation',
        text: 'Impostos',
        urlVideo: 'https://www.youtube.com/watch?v=wNN7R6cVEa0',
        class: 'py-3',
      },
      {
        icon: 'trending_up',
        color: 'purple',
        nameRouter: 'Expense',
        text: 'Despesas',
        urlVideo: 'https://www.youtube.com/watch?v=QHT-lfld6iA',
        class: 'py-3',
      },
      {
        icon: 'add_circle_outline',
        color: 'indigo',
        text: 'Serviços Adicionais',
        nameRouter: 'AdditionalServices',
        urlVideo: 'https://www.youtube.com/watch?v=cgA72e8XMdY',
        class: 'py-3',
      },
      {
        icon: 'lock',
        color: 'indigo',
        text: 'Certificado Digital',
        nameRouter: 'certificade',
        class: 'py-3',
      },
      {
        icon: 'help_outline',
        color: 'indigo',
        text: 'Central de Ajuda',
        nameRouter: 'HelpCenter',
        urlVideo: 'https://www.youtube.com/watch?v=9C4sTCH_hOw',
        class: 'bottom pb-6',
      },
    ],
  }),
  methods: {
    ...mapActions({
      flipflopSidebar: 'layout/flipflopSidebar',
      consisteSidebar: 'layout/consisteSidebar',
      dialogsChangeVideoShow: 'dialogs/changeVideoShow',
    }),
    navigateTo(url) {
      this.$router.push({ name: url }).catch(() => {})
    },
  },
}
</script>
<style scoped lang="sass">
.bottom
  position: fixed
  left: 0
  bottom: 0
  width: 100%
</style>
